import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"

var Index = () => (
  <Layout>
    <Seo title="Home | Parties Unlimited and Event Rentals" />
    <Banner>
      <StaticImage
        src="../images/balloon_garland.jpg"
        alt="Wide image test"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
  </Layout>
)

export default Index
